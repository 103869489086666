<template>
  <div>
    <div class="active_header">
      <img :src="bannder" />
    </div>
    <div class="content_area">
      <div class="bus" @click="toRecord">
        <img
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/Record.png"
        />
        <div class="title">
          <div>年度业务备案入口</div>
        </div>
      </div>
      <div
        class="bus"
        v-for="(item, index) in newList"
        :key="index"
        @click="toDetail(item.id)"
      >
        <img :src="item.img_url" />
        <div class="title">
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisible_company" width="300px">
      <div>
        <div class="openTitle">
          暂无企业信息, 请到控制台-企业设置 完善您的公司信息
        </div>
        <div class="tc open">
          完善企业信息<i class="el-icon-right cursor" @click="toConsole"></i>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="isMumber"
      width="300px"
      :close-on-click-modal="true"
    >
      <div class="tc openDialogs">
        <span class="blue" @click="goToMember">成为会员</span>,解锁专属权益
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "年度业务备案",
  },
  data() {
    return {
      bannder:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/putBanner.png",
      dialogVisible_company: false,
      isMumber: false,
      newList: [],
    };
  },
  components: {},
  computed: {},
  created() {},
  mounted() {
    console.log(this.$route.query.content_type);
    this.getList();
  },
  watch: {},
  methods: {
    toConsole() {
      this.$router.push({ path: "/console/company/basic" });
    },
    goToMember() {
      this.isMumber = false;
      this.$router.push("/memberShip");
    },
    toRecord() {
      if (!this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      } else if (!this.USER_INFO.company_id) {
        this.dialogVisible_company = true;
        return;
      } else if (!this.USER_INFO || !this.USER_INFO.aila_no) {
        this.isMumber = true;
        return;
      }
      this.$router.push("/record_new");
    },
    toDetail(id) {
      let routeUrl = this.$router.resolve({
        path: "/detail",
        query: {
          id: id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    async getList() {
      let params = {
        content_type: this.$route.query.content_type,
        source: this.PJSource,
        nopage: 1,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      if (res.success) {
        this.newList = res.data;
      }
    },
  },
};
</script>

<style scoped lang="less">
.active_header {
  position: relative;
  padding-top: 14.6%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: -1px;
    left: 0;
  }
}
.content_area {
  margin-top: 60px;
  margin-bottom: 237px;
  display: flex;
  flex-wrap: wrap;
  .bus:nth-child(4) {
    margin-right: 0;
  }
  .bus:nth-child(8) {
    margin-right: 0;
  }
  .bus:nth-child(12) {
    margin-right: 0;
  }
}

.bus {
  width: 282px;
  height: 254px;
  background: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(121, 121, 121, 0.15);
  border-radius: 8px;
  margin-right: 24px;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer;
  img {
    width: 282px;
    height: 160px;
  }
  .title {
    div {
      padding: 16px;
      font-size: 16px;
      &:hover {
        color: #15306f;
      }
    }
  }
}

.blue {
  color: #409eff;
  cursor: pointer;
}

.openDialogs {
  height: 100px;
  line-height: 100px;
}
.open {
  margin: 20px 0 0;
  i {
    color: #049ded;
    margin-left: 5px;
    border: 1px solid #049ded;

    border-radius: 50%;
  }
}
</style>
